<template>


  <main>










    <div id="googleMap"></div>




    <div class="col-12" v-if="!Number.isNaN(this.fromlat) && !Number.isNaN(this.fromlng) && !Number.isNaN(this.tolat) && !Number.isNaN(this.tolng)">
      <div class="card mb-6 p-3 shadow text-center">

        <ul class="list-group text-start">

          <li class="list-group-item d-flex justify-content-between align-items-start">
            <strong>Total Time:</strong>
            <span class="ms-auto">{{ travel_time }}</span>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-start">
            <strong>Distance:</strong>
            <span class="ms-auto">{{ distnce }}</span>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-start">
            <strong>Total Cost:</strong>
            <span class="ms-auto">${{ toll_cost }}</span>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-start">
            <a target="_blank" @click="downloadJornyDetail()">
              <i class="ni ni-cloud-download-95"></i>
              <!-- Add your desired icon class here -->
              Download Trip Info
            </a>
          </li>
        </ul>

        <div class="row mt-3">
          <div class="col-md-12 d-flex justify-content-start align-items-center" v-for="(agency, index) in agencyData"
            :key="index">
            <div class="dot col-md-2" :style="{ backgroundColor: agency.color }"></div>
            <div class="label ml-2 col-md-7">
              {{ agency.agency }}
            </div>
            <div class="label ml-2 col-md-3">
              $ {{ agency.amount }}
            </div>
          </div>
        </div>
        <div class="p-3 text-center">
          <h5 class="mb-3 fw-bold">More Filters</h5>
          <div class="mb-3">
            <label for="filterOption1" class="form-label">Filter by Agency
            </label>
            <select class="form-select" id="filterOption1" v-model="selectedAgency">
              <option value="" selected>Select</option>
              <option v-for="(option, index) in agencyData" :key="index" :value="`${option.index}`">
                {{ option.agency }}
              </option>
            </select>
          </div>
          <div class="mb-3">
            <label for="filterOption2" class="form-label">Filter by Class</label>
            <select class="form-select" id="filterOption2" v-model="selectedClass">
              <option v-for="index in 6" :key="index" :value="index">
                {{ `Class ${index}` }}
              </option>
            </select>
          </div>
          <div class="mb-3">
            <label for="filterOption2" class="form-label">Filter by Payment method</label>
            <select class="form-select" id="filterOption2" v-model="selectedPaymentMethod">
              <option v-for="(option, index) in paymentMethodData" :key="index" :value="`${option.id}`">
                {{ option.name }}
              </option>
            </select>
          </div>

          <div class="mb-3">
            <label for="filterOption2" class="form-label ">Router key</label>
            <div class="d-flex justify-content-between align-items-center">
              <div
                style="min-width: 50px ; max-width: 50px; height: 10px; background-color: #8841e8; border-radius: 3px;">
              </div>
              <h6>Route 1</h6>
              <div style="min-width: 50px; max-width: 50px;height: 10px; background-color: #273e06; border-radius: 3px">
              </div>
              <h6>Route 2</h6>
              <div
                style="min-width: 50px;max-width: 50px; height: 10px; background-color:  #6B552F; border-radius: 3px">
              </div>
              <h6>Route 3</h6>

            </div>
          </div>

          <div class="mb-3">
            <label for="filterOption2" class="form-label">Chose Route</label>
            <select class="form-select" id="filterOption2" v-model="selectedRoutes">
              <option v-for="(option, index) in RoutesData" :key="index" :value="`${option.id}`">
                {{ option.name }}
              </option>
            </select>
          </div>


          <div class="mb-3">
            <label for="filterOption3" class="form-label">Time of Travel</label>
            <input type="datetime-local" class="form-control" placeholder="MM/DD/YYYY" v-model="travelTime"
              id="filterOption3" />
          </div>

          <div class="mb-3 row">
            <label class="form-label col-12">Mode of Transport</label>
            <div class="col-6">
              <div class="form-check">
                <input class="form-check-input" type="radio" name="transportMode" checked id="drivingOption "
                  v-model="travelMode" value="DRIVING" />
                <label class="form-check-label" for="drivingOption">
                  Driving
                </label>
              </div>
            </div>
            <div class="col-6">
              <div class="form-check">
                <input class="form-check-input" type="radio" name="transportMode" id="transitOption"
                  v-model="travelMode" value="TRANSIT" />
                <label class="form-check-label" for="transitOption">
                  Transit
                </label>
              </div>
            </div>
          </div>
          <br />
          <button type="submit" class="btn btn-primary" @click="processFilters">
            Submit <i class="bi bi-check-circle"></i>
          </button>


        </div>
      </div>
    </div>

  </main>
</template>

<script>
import Minisidebar from "@/components/partials/Sidebar.vue";
import Topbar from "@/components/partials/Topbar.vue";
import Footer from "@/components/partials/Footer.vue";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import "@/assets/css/dataTables.bootstrap4.min.css";
import "@/assets/css/buttons.dataTables.min.css";

import { Form, Field, ErrorMessage } from "vee-validate";
import apirequest from "@/services/opticostApi.js";
import Swal from 'sweetalert2';
export default {
  name: "signups",
  components: {
    Minisidebar,
    VueGoogleAutocomplete,
    Topbar,
    Footer,
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      journey_details_blob: null,

      title: "",
      paymentMethodData: [],
      RoutesData: [{ "name": "Route 1", "id": 0 }],
      selectedRoutes: 0,
      initialSelectedRoutes: -1,
      map: null,
      directionsService: null,
      directionsRenderer: null,
      directionsRenderer2: null,
      directionsRenderer3: null,
      selectedPaymentMethod: 3,
      selectedAgency: "",
      selectedClass: 1,
      colorPalette: ["#3498db", "#e74c3c", "#2ecc71", "#f39c12"],
      axlesData: [],
      agencyData: [],
      fromlat: "",
      travelTime: this.getCurrentDateTimeString(),
      fromlng: "",
      from: "",
      tolat: "",
      tolng: "",
      to: "",
      distnce: "0",
      travel_time: "0",
      toll_cost: "0",

      destination: "-",
      travelMode: "DRIVING",
      selectedAgencies: [],
      directionResponse: null,
      stopsCordinate: [],

    };
  },
  methods: {

    alterStopOver(action, index = null) {
      if (action === "add") {


        this.stopsCordinate.push({ lat: "", lng: "" });

      }
      if (this.stopsCordinate.length > 0 && action === "remove") {


        this.stopsCordinate.splice(index, 1);


      }
    },

    getfromData: function (addressData, placeResultData, id) {
      this.fromlat = placeResultData.geometry.location.lat();
      this.fromlng = placeResultData.geometry.location.lng();
      this.from = placeResultData.formatted_address;
    },

    getToData: function (addressData, placeResultData, id) {
      this.tolat = placeResultData.geometry.location.lat();
      this.tolng = placeResultData.geometry.location.lng();
      this.to = placeResultData.formatted_address;
      // console.log(this.to, this.tolat, this.tolng);
    },

    getLegData: function (addressData, placeResultData, id) {


      const lat = placeResultData.geometry.location.lat();
      const lng = placeResultData.geometry.location.lng();

      this.stopsCordinate.push({ lat: lat, lng: lng });
      //delete * that { lat: "", lng: "" }
      this.stopsCordinate = this.stopsCordinate.filter((item) => item.lat !== "");
    },



    getCurrentDateTimeString() {
      const now = new Date();
      const year = now.getFullYear();
      const month = (now.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
      const day = now.getDate().toString().padStart(2, "0");
      const hours = now.getHours().toString().padStart(2, "0");
      const minutes = now.getMinutes().toString().padStart(2, "0");

      // Format: YYYY-MM-DDTHH:MM
      return `${year}-${month}-${day}T${hours}:${minutes}`;
    },

    async initMap() {
      this.fromlat = parseFloat(this.$route.query.originLat);
      this.fromlng = parseFloat(this.$route.query.originLng);
      this.tolat = parseFloat(this.$route.query.destLat);
      this.tolng = parseFloat(this.$route.query.destLng);

      // console.log(this.fromlat, this.fromlng, this.tolat, this.tolng);

      // Initialize the map
      let map = new google.maps.Map(document.getElementById("googleMap"), {
        center: { lat: 39.8283, lng: -98.5795 }, // Set your desired center coordinates
        zoom: 12,
      });

      // const trafficLayer = new google.maps.TrafficLayer();
      // trafficLayer.setMap(map);

      if (!Number.isNaN(this.fromlat) && !Number.isNaN(this.fromlng) && !Number.isNaN(this.tolat) && !Number.isNaN(this.tolng)) {

        // Initialize DirectionsService and DirectionsRenderer
        this.directionsService = new google.maps.DirectionsService();

        this.directionsRenderer = new google.maps.DirectionsRenderer({
          map: map,
          suppressMarkers: true,
          polylineOptions: {
            strokeColor: "#8841e8",
            strokeWeight: 4,

          },
        });
        this.directionsRenderer2 = new google.maps.DirectionsRenderer({
          map: map,
          suppressMarkers: true,
          polylineOptions: {
            strokeColor: "#273e06",
            strokeWeigh: 4,
          },
        });

        this.directionsRenderer3 = new google.maps.DirectionsRenderer({
          map: map,
          suppressMarkers: true,
          polylineOptions: {
            strokeColor: "#6B552F",
            strokeWeight: 4,
          },
        });



        // // Bind the directions renderers to the map
        // this.directionsRenderer.setMap(this.map);
        // this.directionsRenderer2.setMap(this.map);


        // Create custom markers
        const f_lat = parseFloat(this.fromlat);
        const f_lng = parseFloat(this.fromlng);

        const t_lat = parseFloat(this.tolat);
        const t_lng = parseFloat(this.tolng);



        const startMarker = this.createMarker(
          map,
          { lat: f_lat, lng: f_lng },
          "Start Location",
          "green",
          {
            title: "Origin",
            body: this.from,
          }
        );
        //lop through stopsCordinate  


        const endMarker = this.createMarker(
          map,
          { lat: t_lat, lng: t_lng },
          "End Location",
          "red",
          {
            title: "Destination",
            body: this.to,
          }
        );

        const params = {
          origin: f_lat + "," + f_lng,
          destination: t_lat + "," + t_lng,
        };
        const agencyIds = this.selectedAgencies.map(
          (selectedAgency) => selectedAgency.agency_id
        );
        var routesData = []
        if (this.initialSelectedRoutes === -1) {
          // const
          routesData = await this.calculateAndDisplayRoute({ lat: f_lat, lng: f_lng }, { lat: t_lat, lng: t_lng });
        }
        else {
           routesData = await this.redisplayDisplayRoute();
        }
        const body = {
          payment_method: this.selectedPaymentMethod,
          v_class: this.selectedClass,
          travel_date_time: this.travelTime,
          with_trs_agency_ids: agencyIds,
          stopovers: this.stopsCordinate,

          routesData: routesData,


        };
        // console.log(body);
        const queryParams = new URLSearchParams(params);


        apirequest(
          "POST",
          `/routeinterpolatecoordinates?${queryParams}`,
          body
        ).then(async (response) => {
          var markers = [];

          const markes_response = response.markers;
          this.toll_cost = response.total_amount.toFixed(2);
          this.agencyData = response.rates;
          this.downloadFormattedText(routesData);



          markers.push(startMarker);
          for (let i = 0; i < markes_response.length; i++) {
            const marker = this.createMarker(
              map,
              markes_response[i].coordinate,
              markes_response[i].name,
              "blue",
              {
                title: response.markers[i] ? response.markers[i].agency_name : "",
                body: `${markes_response[i].name} ${response.rates[i] ? "$" + response.rates[i].amount : ""
                  }`,
              }
            );

            markers.push(marker);
          }

          for (let i = 0; i < this.stopsCordinate.length; i++) {
            const stop = this.stopsCordinate[i];
            const stopMarker = this.createMarker(
              map,
              { lat: stop.lat, lng: stop.lng },
              `Stop ${i + 1}`,
              "red",
              {
                title: "Stop",
                body: `Stop ${i + 1}`,
              }
            );
            markers.push(stopMarker);
          }

          markers.push(endMarker);


          for (let i = 0; i < response.rates.length; i++) {
            const rate = response.rates[i];
            if (rate.agency_type == 1) {

              const waypoints = [
                new google.maps.LatLng(rate["route_cordinate"]["entry"]),
                new google.maps.LatLng(rate["route_cordinate"]["exit"]),


                // Add more waypoints as needed

              ];

              this.drawRoute(map, waypoints, this.travelMode, rate.color, 4);
            }
          }
          // this.showTransponderFiltersModel();
        });
      }
      // Get directions and display on the map
    },
    downloadFormattedText(journeyStats) {
      // Prepare the text content for the journey
      let textContent = `Journey from: ${this.origin} to ${this.destination}\n`;
      textContent += `Total Distance: ${this.distnce}\n`;
      textContent += `Total Duration: ${this.travel_time}\n`;
      textContent += `Route Details:\n`;

      // Ensure routeDetails is an array before iterating
      // if (Array.i(journeyStats)) {



      journeyStats.legs.forEach((leg, legIndex) => {

        textContent += `  Leg ${legIndex + 1}:\n`;
        textContent += `    Start Address: ${leg.start_address}\n`;
        textContent += `    End Address: ${leg.end_address}\n`;
        textContent += `    Distance: ${leg.distance.text}\n`;
        textContent += `    Duration: ${leg.duration.text}\n`;
        // textContent += `    Duration in Traffic: ${leg.duration_in_traffic.text}\n`;

        // Steps in the leg

        textContent += `    Steps:\n`;
        if (Array.isArray(leg.steps)) {
          leg.steps.forEach((step, stepIndex) => {

            textContent += `      Step ${stepIndex + 1}:\n`;
            textContent += `        Distance: ${step.distance.text}\n`;
            textContent += `        Duration: ${step.duration.text}\n`;
            // textContent += `        From: (${step.start_location.lat}, ${step.start_location.lng})\n`;
            // textContent += `        To: (${step.end_location.lat}, ${step.end_location.lng})\n`;
            // textContent += `        Travel Mode: ${step.travel_mode}\n`;

            // Stripping HTML tags from instructions to make them plain text
            const instruction = step.instructions.replace(/<[^>]*>/g, ''); // Removing all HTML tags
            textContent += `        Directions: ${instruction}\n`;
          });
        }
      });


      // } else {
      //   textContent += "No route details found.\n";
      // }

      // Create a Blob from the text content and trigger the download
      this.journey_details_blob = new Blob([textContent], { type: 'text/plain' });
      // const link = document.createElement('a');
      // link.href = URL.createObjectURL(journey_details_blob);
      // link.download = 'journey_details.txt';
      // link.click();

    },
    downloadJornyDetail() {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(this.journey_details_blob);
      link.download = 'journey_details.txt';
      link.click();
    },
    createMarker(map, position, label, color, marker_info_objct) {
      var contentString =
        '<div class="info-window">' +
        `<h6>${marker_info_objct.title}</h6>` +
        `<p>${marker_info_objct.body}</p>` +
        "</div>";

      var infowindow = new google.maps.InfoWindow({
        content: contentString,
      });

      var marker = new google.maps.Marker({
        position: position,
        map: map,
        label: {
          text: label,
          color: color,
        },
        icon: {
          url: `http://maps.google.com/mapfiles/ms/icons/${color}-dot.png`,
          labelOrigin: new google.maps.Point(20, 0),
        },
      });
      marker.addListener("click", function () {
        infowindow.open(map, marker);
      });

      return marker;
    },

    async calculateAndDisplayRoute(startMarker, endMarker) {
      // console.log("called the api for directions");
      // const start = startMarker.getPosition();
      // const end = endMarker.getPosition();
      // console.log(startMarker, endMarker);
      const start = new google.maps.LatLng(startMarker.lat, startMarker.lng);
      const end = new google.maps.LatLng(endMarker.lat, endMarker.lng);

      // Simplifying waypoints creation with map() directly if stopsCordinate exists
      const waypoints = this.stopsCordinate.length
        ? this.stopsCordinate.map(coord => new google.maps.LatLng(coord.lat, coord.lng))
        : [];

      // Directions parameters
      const directionParam = {
        origin: start,
        destination: end,
        waypoints: waypoints.length > 0 ? waypoints.map(location => ({ location, stopover: true })) : undefined,
        travelMode: this.travelMode,
        provideRouteAlternatives: true,
        optimizeWaypoints: true,
        drivingOptions: {
          departureTime: new Date(), // Using real-time traffic data
          trafficModel: google.maps.TrafficModel.BEST_GUESS, // Estimate based on current traffic
        },
      };

      var selectedRouteData = []
      await this.directionsService.route(directionParam, (response, status) => {
        // console.log(response);

        if (status === "OK") {
          const routes = response.routes;
          this.directionResponse = response;




          this.distnce = response.routes[0].legs[0].distance.text;
          this.travel_time = response.routes[0].legs[0].duration.text;



          // Check if routes exist
          if (routes.length > 0) {
            selectedRouteData = routes[this.selectedRoutes];
            // console.log("-----------------------",selectedRouteData, "-------------", this.selectedRoutes);
            const routesData = [];

            // Adjust routes for different selected routes
            if (this.initialSelectedRoutes === -1) {
              // If no route is selected, display all routes
              this.directionsRenderer.setDirections({ ...response, routes: routes.slice(0) });
              this.directionsRenderer2.setDirections({ ...response, routes: routes.slice(1) });

              if (routes.length > 2) {
                this.directionsRenderer3.setDirections({ ...response, routes: routes.slice(2) });
              }

              // Dynamically populate RoutesData based on the number of routes
              for (let i = 0; i < routes.length; i++) {
                routesData.push({ name: `Route ${i + 1}`, id: i });
              }
              this.RoutesData = routesData;
              this.initialSelectedRoutes = 0;
            } else {
              // When a specific route is selected


              const selectedRoute = routes.slice(+this.selectedRoutes);
              if (+this.selectedRoutes === 0) {
                this.directionsRenderer.setDirections({ ...response, routes: selectedRoute });
              } else if (+this.selectedRoutes === 1) {
                this.directionsRenderer2.setDirections({ ...response, routes: selectedRoute });
              } else if (+this.selectedRoutes === 2) {
                this.directionsRenderer3.setDirections({ ...response, routes: selectedRoute });
              }
            }


            // Set the RoutesData dynamically based on the number of available routes

          }

        } else {
          window.alert("Directions request failed due to " + status);
        }

      });
      return selectedRouteData

    },


    async redisplayDisplayRoute() {
      // console.log("called rerender  directions");
      var selectedRouteData = []
      const response = this.directionResponse

      const routes = response.routes;

      this.distnce = response.routes[0].legs[0].distance.text;
      this.travel_time = response.routes[0].legs[0].duration.text;



      // Check if routes exist
      if (routes.length > 0) {
        selectedRouteData = routes[this.selectedRoutes];
        // console.log("-----------------------",selectedRouteData, "-------------", this.selectedRoutes);
        const routesData = [];

        const selectedRoute = routes.slice(+this.selectedRoutes);
        if (+this.selectedRoutes === 0) {
          this.directionsRenderer.setDirections({ ...response, routes: selectedRoute });
        } else if (+this.selectedRoutes === 1) {
          this.directionsRenderer2.setDirections({ ...response, routes: selectedRoute });
        } else if (+this.selectedRoutes === 2) {
          this.directionsRenderer3.setDirections({ ...response, routes: selectedRoute });
        }
      }


      return selectedRouteData

    },

    drawRoute(
      map,
      waypoints,
      travelMode = this.travelMode,

      strokeColor = "#0000FF",
      strokeWeight = 12
    ) {
      const directionsService = new google.maps.DirectionsService();
      const directionsRenderer = new google.maps.DirectionsRenderer({
        map: map,
        suppressMarkers: true,
        polylineOptions: {
          strokeColor: strokeColor,
          strokeWeight: strokeWeight,
        },
      });


      const request = {
        origin: waypoints[0],
        destination: waypoints[waypoints.length - 1],
        waypoints: waypoints
          .slice(1, -1)
          .map((waypoint) => ({ location: waypoint, stopover: true })),
        travelMode: travelMode,
      };

      directionsService.route(request, (response, status) => {
        if (status === "OK") {
          directionsRenderer.setDirections(response);
        } else {
          window.alert("Directions request failed due to " + status);
        }
      });
    },

    getAxels() {
      apirequest("get", "/axels").then((response) => {
        this.axlesData = response;
      });
    },
    // getAgencies() {
    //   apirequest("get", "/agency").then((response) => {
    //     this.agencyData = response;
    //   });
    // },
    getPaymentMethod() {
      apirequest("get", "/paymentmethods").then((response) => {
        this.paymentMethodData = response;
      });
    },

    processFilters() {
      this.getCurrentDateTimeString(),
        this.initMap();
        window.scrollTo({ top: 0, behavior: 'smooth' });
    },
  },

  mounted() {
    this.map = new google.maps.Map(document.getElementById("googleMap"), {
      center: { lat: 39.8283, lng: -98.5795 }, // Set your desired center coordinates
      zoom: 6,
    });
    // const trafficLayer = new google.maps.TrafficLayer();
    // trafficLayer.setMap(this.map);

    // this.getAgencies();
    this.getAxels();
    this.getPaymentMethod();
    this.initMap();
  },
};
</script>

<style>
#googleMap {
  height: 70vh;
}

.dot {
  height: 25px;
  width: 25px;
  background-color: #84dffa;
  border-radius: 50%;

  margin: 5px;
}
</style>
