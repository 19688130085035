import { createWebHistory, createRouter } from "vue-router";
import SignIn from "@/components/auth/SignIn.vue";
import MapComponetMobile from "@/components/auth/MapComponetMobile.vue";
import MapComponet from "@/components/MapComponet.vue";
const authGuard = (to, from, next) => {
  var isAuthenticated = false;
  let user = JSON.parse(localStorage.getItem("user"));
  if (user.access_token) isAuthenticated = true;
  else isAuthenticated = false;
  if (isAuthenticated) next();
  else next("/login");
};

const routes = [
  { path: "/", component: MapComponet, meta: { title: "Sign In" } },
  { path: "/login", component: SignIn, meta: { title: "Sign In" } },
  { path: "/mobiletollrates", component: MapComponetMobile, meta: { title: "Mobile" } },
  {
    path: "/forget",
    component: () => import("@/components/auth/Forget.vue"),
    beforeEnter: authGuard,
    meta: { title: "Forget Credentials" },
  },
  {
    path: "/landing-page",
    component: () => import("@/components/MapComponet.vue"),
    // beforeEnter: authGuard,
    meta: { title: "Landing Page" },
  },
  {
    path: "/tolldistribution",
    component: () => import("@/components/AgencyCoverage.vue"),
    // beforeEnter: authGuard,
    meta: { title: "Toll Distribution" },
  },
  {
    path: "/tollrates",
    component: () => import("@/components/TollRate.vue"),
    // beforeEnter: authGuard,
    meta: { title: "Toll Rates" },
  },

  { path: "/index.html", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkExactActiveClass: "active",
});

router.beforeEach((toRoute, fromRoute, next) => {
  window.document.title =
    "Innovative - " +
    (toRoute.meta && toRoute.meta.title ? toRoute.meta.title : "");
  next();
});

export default router;
