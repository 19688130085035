<template destination="hello">
  <div class="min-height-300 bg-primary position-absolute w-100"></div>
  <aside class="sidenav bg-white navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-4"
    id="sidenav-main">
    <div class="sidenav-header">
      <i class="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none"
        aria-hidden="true" id="iconSidenav"></i>
      <router-link class="navbar-brand m-2" to="/landing-page">
        <img class="text-center" src="../../assets/logo2.png" alt="LOGO" />
        <!-- <span class="ms-1 font-weight-bold">Innovative Toll</span> -->
      </router-link>

    </div>
    <div class="collapse navbar-collapse w-auto h-auto" id="sidenav-collapse-main">
      <h6 class="ps-4 ms-2 text-uppercase text-xs font-weight-bolder opacity-6">
        Toll Opticost
      </h6>
      <ul class="navbar-nav">
        <li class="nav-item">
          <a data-toggle="collapse"  class="nav-link" aria-controls="applicationsExamples"
            role="button" aria-expanded="false">
            <div class="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
              <i class="ni ni-satisfied text-primary text-sm opacity-10"></i>
            </div>
       
            <router-link class="collapse-item" to="/landing-page">     <span class="nav-link-text ms-1">Toll calculator</span></router-link>
          </a>
          
        </li>
        <li class="nav-item">
          <a data-toggle="collapse" href="/tolldistribution" to="/tolldistribution" class="nav-link" aria-controls="applicationsExamples"
            role="button" aria-expanded="false">
            <div class="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
              <i class="ni ni-bus-front-12 text-warning text-sm opacity-10"></i>
            </div>
            <router-link class="collapse-item" to="/tolldistribution"> 
            <span class="nav-link-text ms-1">Toll distribution</span>
            </router-link>
          </a>
         
        </li>



        <li class="nav-item">
          <a data-toggle="collapse" href="/tollrates" to="/tollrates" class="nav-link" aria-controls="applicationsExamples"
            role="button" aria-expanded="false">
            <div class="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
              <i class="ni ni-books text-warning text-sm opacity-10"></i>
            </div>
            <router-link class="collapse-item" to="/tollrates"> 
            <span class="nav-link-text ms-1">Toll Rates</span>
            </router-link>
          </a>
         
        </li>
      </ul>
    </div>

    <div class="sidenav-footer mx-3 my-3">
      <div class="card card-plain shadow-none" id="sidenavCard">
        <img class="w-60 mx-auto" src="../../assets/img/illustrations/icon-documentation.svg"
          alt="sidebar_illustration" />
        <div class="card-body text-center p-3 w-100 pt-0">
          <div class="docs-info">
            <h6 class="mb-0">Need Something else?</h6>
            <p class="text-xs font-weight-bold mb-0">Click here</p>
          </div>
        </div>
      </div>
      <router-link to="/landing-page" class="btn btn-primary btn-sm mb-0 w-100" type="button">HOME</router-link>
    </div>
  </aside>
</template>

<script setup>
import { useStore } from "vuex";
import { watchEffect, onMounted, onBeforeUnmount } from "vue";

const store = useStore();
const isLogged = store.state.auth.status.loggedIn;

const props = defineProps({
  client_type: Number,
});

watchEffect(() => {
  $(document).ready(() => {
    const isPinned = $("body").hasClass("g-sidebar-pinned");
    if (isLogged) {
      $("#iconNavbarSidenav").on("click", () => {
        $("body").addClass("g-sidenav-show bg-gray-100");
        const bodyPinned = $("body").hasClass("g-sidebar-pinned");
        if (!isPinned) $("body").addClass("g-sidenav-pinned");
      });
    } else {
      $("body").removeClass("g-sidenav-show bg-gray-100");
    }
  });
});
</script>
